import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { compose, pure } from 'recompose';

import Layout from '@/components/layout';
import SEO from '@/components/seo';

type Props = {
  classes: {
    componentWrapper: string;
  };
};

const NotFoundPage = (props: Props) => {
  const { classes } = props;

  return (
    <Layout>
      <SEO title="404: ページが見つかりません" />
      <div className={classes.componentWrapper}>
        <h1>ページが見つかりません</h1>
        <p>お探しのページは存在しないようです... 残念です。</p>
      </div>
    </Layout>
  );
};

const styles = (_theme: Theme) =>
  createStyles({
    componentWrapper: {
      margin: '0 auto',
      maxWidth: 1280,
      paddingTop: 0,
    },
  });

export default compose(pure, withStyles(styles))(NotFoundPage);
